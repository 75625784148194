<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import type { ILeaseReportTemplate } from '@portfolio'
import { useApiDeleteReportTemplate } from '@reports/composables/useApiReportTemplates'
import type { RouteLocationRaw } from 'vue-router'

interface Props {
  divisionId: string
  to: string | RouteLocationRaw
  template: ILeaseReportTemplate
  isExportingXlsx?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isExportingXlsx: false,
})

const emits = defineEmits(['download', 'customize'])

const handleViewReportClick = (
  event: MouseEvent,
  emitName: 'download' | 'customize',
) => {
  event.stopPropagation()
  event.preventDefault()
  emits(emitName)
}
const { mutateAsync: deleteTemplate, isPending } = useApiDeleteReportTemplate(
  props.divisionId,
)
const openDeleteConfirmation = useConfirmHandler(
  () => deleteTemplate(props.template.id!),
  {
    modal: {
      title: 'Delete Template',
      body: 'Are you sure you want to delete this template?\nIt will be deleted to all users.',
      submitButton: 'Delete',
      cancelButton: 'Cancel',
      // @ts-expect-error
      loading: isPending,
    },
    loadingMessage: 'Deleting template...',
    successMessage: 'Template deleted successfully',
    errorMessage: 'Failed to delete template',
  },
)
</script>

<template>
  <Menu ref="popover" v-slot="{ open }" as="div" class="relative">
    <MenuButton
      class="hover:border-primary hover:bg-primary focus-visible:bg-primary rounded-md border border-transparent p-2 hover:text-black focus-visible:text-black focus-visible:outline-none"
      :class="{
        'bg-primary text-black': open,
      }"
    >
      <Icon name="kebab-menu" />
    </MenuButton>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="divide-gray-650 absolute right-0 z-10 mt-2 flex origin-top-right flex-col divide-y rounded-md bg-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus-visible:outline-none"
      >
        <MenuItem>
          <Button
            color="transparentSecondary"
            class="justify-start gap-2 whitespace-nowrap rounded-b-none"
            icon="file-report"
            :to="to"
          >
            View Report
          </Button>
        </MenuItem>

        <MenuItem>
          <Button
            color="transparentSecondary"
            class="justify-start gap-2 whitespace-nowrap rounded-none"
            icon="customize"
            @click.stop="handleViewReportClick($event, 'customize')"
          >
            Customise
          </Button>
        </MenuItem>

        <MenuItem>
          <Button
            :disabled="isExportingXlsx"
            :loading="isExportingXlsx"
            color="transparentSecondary"
            class="justify-start gap-2 whitespace-nowrap rounded-none"
            icon="file-download"
            @click.stop="handleViewReportClick($event, 'download')"
          >
            Download
          </Button>
        </MenuItem>

        <MenuItem v-if="template.isCustomTemplate">
          <Button
            color="transparentDanger"
            class="justify-start gap-2 whitespace-nowrap rounded-t-none"
            icon="trash"
            @click.stop="
              $event.stopPropagation(),
                $event.preventDefault(),
                openDeleteConfirmation()
            "
          >
            Delete
          </Button>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>
